import {
  Component,
  Input,
  SimpleChanges,
  ViewChild,
  Output,
  EventEmitter,
  AfterViewInit,
  DestroyRef,
} from '@angular/core';

import { PagedData } from 'src/app/model';
import { MatPaginator } from '@angular/material/paginator';
import { BaseFilter } from '../filters';
import { CdkDragDrop, moveItemInArray } from '@angular/cdk/drag-drop';
import { MatSort } from '@angular/material/sort';
import { MatTableDataSource } from '@angular/material/table';
import { BreakpointObserver, Breakpoints } from '@angular/cdk/layout';
import { Observable, Subject } from 'rxjs';
import { debounceTime, map } from 'rxjs/operators';
import { SelectionModel } from '@angular/cdk/collections';
import { EntityService } from '../../services/entity.service';
import { Entity, SavedColumns } from 'src/app/model';
import { NotificationService } from 'src/app/services/notification.service';
import { LoginService } from 'src/app/services/login.service';
import { LayoutService } from '../../services/layout.service';
import { takeUntilDestroyed } from '@angular/core/rxjs-interop';
import { FileUploadService } from '../../services/file-upload.service';

@Component({
  selector: 'app-grid',
  templateUrl: './grid.component.html',
  styleUrls: ['./grid.component.css'],
})
export class GridComponent implements AfterViewInit {
  @Input() ID: string;
  @Input() ELEMENT_DATA: PagedData<any>;
  @Input() ARRAY_DATA: any[];
  @Input() ATTR: any[];
  @Input() PAGESIZEOPTIONS: number[] = [];
  @Output() getData = new EventEmitter<BaseFilter>();
  @Output() ONROWCLICK = new EventEmitter<any>();
  @Output() ONACTIONCLICK = new EventEmitter<any>();
  @Output() ONBALKACTIONCLICK = new EventEmitter<any>();
  @Output() ONCOLSEARCH = new EventEmitter<any>();
  @Input() FILTERS: BaseFilter;
  @Input() showSearch: boolean = true;
  @Input() SHOWTRASHFILTER: boolean = false;
  @Input() MULTISELECT: boolean = false;
  @Input() SEARCHONCOLS: boolean = false;
  @Input() ISDATAPAGED: boolean = true;
  @Input() ENTITY: Entity;
  @Input() ENT_CODE: string;

  @ViewChild(MatPaginator) paginator: MatPaginator;
  @ViewChild(MatSort) sort: MatSort;

  displayedColumns: string[] = [];
  selectedColumns: string[] = [];
  localstorageKey: string;
  dataSource: any;
  selection = new SelectionModel<any>(true, []);
  rowSearchChanged = new Subject<any>();

  showSaveViewDialog: boolean = false;
  viewName: string = '';
  savedColumns: SavedColumns[] = [];
  selectedView: SavedColumns;
  data: any[] = [];
  entity: string;

  constructor(
    private entityService: EntityService,
    private notificationService: NotificationService,
    private loginService: LoginService,
    public layoutService: LayoutService,
    private destroyRef: DestroyRef,
    private fileUploadService: FileUploadService
  ) {
    this.rowSearchChanged.pipe(debounceTime(1000)).subscribe((res) => {
      this.searchOnColumn(res.ev, res.col);
    });
  }

  ngOnInit(): void {
    let isHandset: boolean = false;
    this.layoutService.isHandset$
      .pipe(takeUntilDestroyed(this.destroyRef))
      .subscribe((x) => {
        isHandset = x;
      });

    if (this.MULTISELECT) {
      this.selectedColumns.push('checkbox');
      this.selection.changed.subscribe((selectionModel) => {
        let selectedIds = selectionModel.source.selected.map(
          (entity) => entity.id
        );
        this.entityService.selectedItemIds.next(selectedIds);
      });
    }

    let additionalLocalStorageKey = isHandset ? 'mobile' : 'desktop';
    this.localstorageKey = 'grid_' + this.ID + '_' + additionalLocalStorageKey;
    let savedColumnsJSON = localStorage.getItem(this.localstorageKey);

    for (let column of this.ATTR) {
      this.displayedColumns.push(column.attr);
      if (!savedColumnsJSON && !isHandset && column.view) {
        this.selectedColumns.push(column.attr);
      }
      if (!savedColumnsJSON && isHandset && column.mobileView) {
        this.selectedColumns.push(column.attr);
      }
    }

    if (savedColumnsJSON) {
      let savedColumns = JSON.parse(savedColumnsJSON).filter(
        (x) =>
          x != 'deactivateBulkAction' &&
          x != 'activateBulkAction' &&
          x != 'editBulkAction'
      );
      this.selectedColumns = [];

      if (
        this.MULTISELECT &&
        savedColumns.indexOf((x) => x == 'checkbox') == -1
      ) {
        this.selectedColumns.push('checkbox');
      }
      for (let savedColumn of savedColumns) {
        if (this.displayedColumns.find((x) => x == savedColumn)) {
          this.selectedColumns.push(savedColumn);
        }
      }
    }
    this.loadSavedColumns();
  }

  public convertToDate(datestr: string): Date {
    return datestr ? new Date(datestr) : null;
  }
  ngAfterViewInit() {
    this.dataSource.sort = this.sort;
  }

  ngOnChanges(changes: SimpleChanges): void {
    let data = this.ISDATAPAGED ? this.ELEMENT_DATA.data : this.ARRAY_DATA;

    this.dataSource = new MatTableDataSource(data);
    this.dataSource.sort = this.sort;
    this.selection.clear();
    if (this.FILTERS.currentPage == 1) {
      this.paginator?.firstPage();
    }
  }

  changeFilter() {
    this.getData.emit(this.FILTERS);
  }

  searchStr() {
    this.FILTERS.currentPage = 1;
    if (this.paginator) {
      this.paginator.pageIndex = 0;
    }
    this.changeFilter();
  }

  changePage(event) {
    localStorage.setItem('gridPageSize', JSON.stringify(event.pageSize));
    this.FILTERS.pageSize = event.pageSize;
    this.FILTERS.currentPage = event.pageIndex + 1;
    this.changeFilter();
  }

  sortData(event) {}

  rowClicked(obj: any) {
    this.ONROWCLICK.emit(obj);
  }

  actionClicked(item: any, action: string, fldId) {
    let obj = {
      item: item,
      action: action,
      fldId: fldId,
    };
    this.ONACTIONCLICK.emit(obj);
  }

  bulkActionClicked(action: string, fldId) {
    let obj = {
      items: this.selection.selected,
      action: action,
      fldId: fldId,
    };
    this.ONBALKACTIONCLICK.emit(obj);
  }

  drop(event: CdkDragDrop<string[]>) {
    moveItemInArray(
      this.selectedColumns,
      event.previousIndex + (this.MULTISELECT ? 1 : 0),
      event.currentIndex + (this.MULTISELECT ? 1 : 0)
    );
    localStorage.setItem(
      this.localstorageKey,
      JSON.stringify(this.selectedColumns)
    );
  }

  updateSelectedColumns($event) {
    this.selectedColumns = this.selectedColumns.filter(
      (item) => item !== 'checkbox'
    );
    let difference = this.selectedColumns
      .filter((item) => !$event.includes(item))
      .concat($event.filter((item) => !this.selectedColumns.includes(item)));
    if (this.selectedColumns.includes(difference[0])) {
      this.selectedColumns = this.selectedColumns.filter(
        (item) => item !== difference[0]
      );
    } else {
      this.selectedColumns.push(difference[0]);
    }
    this.storeSettings();
  }

  storeSettings(addCheckbox: boolean = true) {
    if (this.MULTISELECT && addCheckbox) {
      this.selectedColumns.unshift('checkbox');
    }
    localStorage.setItem(
      this.localstorageKey,
      JSON.stringify(this.selectedColumns)
    );
  }

  public getActionColumns() {
    return this.ATTR.filter(
      (x) => x.type == 'action' || x.type == 'bulk_action'
    );
  }

  masterToggle() {
    if (this.isAllSelected()) {
      this.selection.clear();
      return;
    }

    this.selection.select(...this.dataSource.data);
  }

  isAllSelected() {
    const numSelected = this.selection.selected.length;
    const numRows = this.dataSource.data.length;
    return numSelected === numRows;
  }

  checkboxLabel(row?: any): string {
    if (!row) {
      return `${this.isAllSelected() ? 'deselect' : 'select'} all`;
    }
    return `${this.selection.isSelected(row) ? 'deselect' : 'select'} row ${row.position + 1}`;
  }

  getColumns(): any[] {
    return this.ATTR.filter((x) => x.type != 'bulk_action');
  }

  searchOnColumn(event, column: any) {
    const value = event.target.value;
    this.ONCOLSEARCH.emit({ value, column });
  }

  getSearchColumns() {
    let searchColumns: string[] = [];
    for (let i = 0; i < this.selectedColumns.length; i++) {
      searchColumns.push('search_' + this.selectedColumns[i]);
    }

    return searchColumns;
  }

  updateTableData() {
    const selectedColumns = this.selectedColumns;
    let data = this.ISDATAPAGED ? this.ELEMENT_DATA.data : this.ARRAY_DATA;
    data = data.map((item) => {
      const newItem = {};
      for (const column of selectedColumns) {
        newItem[column] = item[column];
      }
      return newItem;
    });
    this.dataSource.data = data;
  }

  addSavedColumns() {
    let newsavedColumns: SavedColumns = new SavedColumns();
    newsavedColumns.title = this.viewName;
    newsavedColumns.selected_columns = this.selectedColumns.join(',');
    newsavedColumns.entity_code = this.ENT_CODE || this.ID;
    newsavedColumns.user_id = this.loginService.getLoginUser().id;

    this.entityService
      .saveColumns(newsavedColumns)
      .then((res) => {
        if (res.success) {
          this.notificationService.showSnackbarMessage(
            'Messages.successfulSave'
          );
          this.savedColumns.push(newsavedColumns);
          this.viewName = '';
        } else {
          this.notificationService.showSnackbarMessage('Messages.failSave');
        }
      })
      .catch((error) => {
        this.notificationService.showSnackbarMessage('Messages.failSave');
      });
  }

  applySavedColumns(savedColumnsView: SavedColumns) {
    const selectedColumnsArray = savedColumnsView.selected_columns.split(',');

    this.displayedColumns = [...selectedColumnsArray];
    this.selectedColumns = [...selectedColumnsArray];

    this.selectedView = savedColumnsView;
    this.storeSettings(false);
  }

  deleteSavedColumns(view: SavedColumns) {
    this.entityService.deleteView(view.id).then((response) => {
      if (response.success) {
        const index = this.savedColumns.indexOf(view);
        if (index !== -1) {
          this.savedColumns.splice(index, 1);
        }
      }
    });
  }

  updateLocalStorage() {
    const viewsKey = `savedColumnsView_${this.ENT_CODE}`;
    localStorage.setItem(viewsKey, JSON.stringify(this.savedColumns));
  }

  cancelSaveView() {
    this.showSaveViewDialog = false;
    this.viewName = '';
  }

  loadSavedColumns() {
    this.entityService.getSavedColumns(this.ENT_CODE).then((res) => {
      this.savedColumns = res;
    });
  }

  toggleSaveViewDialog(event: MouseEvent) {
    event.stopPropagation();
    if (!this.showSaveViewDialog) {
      this.viewName = '';
    }
    this.showSaveViewDialog = !this.showSaveViewDialog;
  }

  onExportToExcel() {
    const gridColumns = this.selectedColumns.filter(
      (column) => column !== 'checkbox'
    );
    this.entityService
      .exportGridToExcel(this.FILTERS, this.ENT_CODE, gridColumns)
      .then((response: Blob | null) => {
        if (response !== null) {
          const blob = new Blob([response], {
            type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',
          });
          this.fileUploadService.downloadToDevice(blob, 'grid-data.xlsx');
        }
      });
  }
}
