<app-data-list-filters *ngIf="entity" class="filtersDiv" [hidden]="!FILTERMODE" [ENTITY]="entity"
  [FILTERS]="entityFilters" (getData)="refreshEntityList($event)">
</app-data-list-filters>

<div [class.gridDiv]="FILTERMODE">
  <app-grid *ngIf="entityObj && gridColumns && ENTITY != 'user'" [ID]="'entity_'+ENTITY+'_'+ENTITY"
    [ELEMENT_DATA]="entityObj" [ATTR]="gridColumns" [FILTERS]="entityFilters" (getData)="refreshEntityList($event)"
    [MULTISELECT]="MULTISELECT" (ONBALKACTIONCLICK)="onBulkActionPress($event)"
    [showSearch]="SHOWSEARCH" (ONROWCLICK)="onSelectEntityItem($event)" (ONACTIONCLICK)="onActionPress($event)"
    [SHOWTRASHFILTER]="SHOWTRASHFILTER" [PAGESIZEOPTIONS]="pageSizeOptions" [SEARCHONCOLS]="SEARCHONCOLS" (ONCOLSEARCH)="onColumnSearch($event)" [ENT_CODE]="ENTITY">
  </app-grid>


  <div class="userDepFilter" [class.userDepFilter-M]="(isHandset$ | async)" [class.userDepFilter]="!(isHandset$ | async)" *ngIf="entityObj && gridColumns && ENTITY == 'user'" >
    <mat-form-field  >
      <mat-label>{{'Company.department' | translate}}</mat-label>
      <mat-select matNativeControl [(ngModel)]="entityFilters.departmentId"  (ngModelChange)="getData()">
        <mat-option [value]="null">--</mat-option>
        <mat-option *ngFor="let department of departments" [value]="department.id">
          {{department.title}} - {{department.location}}
        </mat-option>
      </mat-select>
    </mat-form-field>
  </div>


  <app-grid [hidden]="FILTERMODE" *ngIf="entityObj && gridColumns && ENTITY == 'user'"
    [ID]="'entity_'+ENTITY+'_'+ENTITY" [ELEMENT_DATA]="entityObj" [ATTR]="gridColumns" [FILTERS]="entityFilters"
    (getData)="refreshEntityList($event)" [showSearch]="SHOWSEARCH" (ONROWCLICK)="onSelectEntityItem($event)"
    [SHOWTRASHFILTER]="SHOWTRASHFILTER" [PAGESIZEOPTIONS]="pageSizeOptions" [ENT_CODE]="ENTITY">
  </app-grid>
</div>
