<h2>{{ 'Workflows.selectRecord' | translate }}</h2>
<div>
  <button  mat-fab extended color="primary" class="choice-button" (click)="openCreationDialog()">
    <mat-icon>add_circle</mat-icon>
    {{ 'Workflows.createRecord' | translate }}
  </button>
  <br>
  <br>
  <button  mat-fab extended color="primary" class="choice-button" (click)="openSelectionDialog()">
    <mat-icon>search</mat-icon>
    {{ 'Workflows.connectRecord' | translate }}
  </button>
</div>
<div class="action-section">
  <button mat-raised-button color="warn" [mat-dialog-close]="null">
    {{ 'Generic.cancel' | translate }}
  </button>
</div>
