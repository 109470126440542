import {Component, Inject} from '@angular/core';
import {DIALOG_DATA} from "@angular/cdk/dialog";
import {MatDialog, MatDialogConfig, MatDialogRef} from "@angular/material/dialog";
import {DataItemDiagComponent} from "../../../common/entity/data-item-diag/data-item-diag.component";
import {EntitySelectorDiagComponent} from "../../../common/entity-selector-diag/entity-selector-diag.component";
import {WorkflowService} from "../../../services/workflow.service";
import {NotificationService} from "../../../services/notification.service";

@Component({
  selector: 'app-select-endata-for-workflow-dialog',
  templateUrl: './select-endata-for-workflow-dialog.component.html',
  styleUrls: ['./select-endata-for-workflow-dialog.component.css']
})
export class SelectEndataForWorkflowDialogComponent {

  constructor(@Inject(DIALOG_DATA) public data: any,
              private matDialog: MatDialog,
              private matDialogRef: MatDialogRef<SelectEndataForWorkflowDialogComponent>,
              private workflowService: WorkflowService,
              private notificationService: NotificationService) {
  }

  openCreationDialog() {
    const dialogConfig = new MatDialogConfig();
    dialogConfig.disableClose = true;
    dialogConfig.autoFocus = true;

    dialogConfig.data = {
      itemId: 'new',
      entityCode: this.data.entityChoice,
    };
    this.workflowService.createNewRecordForWorkflow = true;
    const dialogRef = this.matDialog.open(DataItemDiagComponent, dialogConfig);
    dialogRef.afterClosed().subscribe((result: any) => {
      if (result){
        this.workflowService.assignEntdataToWorkflow(this.data.workflowStepId, result).then((res)=>{
          if (res){
            this.notificationService.showSnackbarMessage(
              'Messages.assignEntdataToWorkflowSuccess'
            );
            this.matDialogRef.close('success');
          }
        })
      }
    });
  }

  openSelectionDialog() {
    const dialogConfig = new MatDialogConfig();

    dialogConfig.disableClose = true;
    dialogConfig.autoFocus = true;

    dialogConfig.data = {
      entityCode: this.data.entityChoice,
    };

    const dialogRef = this.matDialog.open(
      EntitySelectorDiagComponent,
      dialogConfig
    );
    dialogRef.afterClosed().subscribe((result: any) => {
      if (result){
        this.workflowService.assignEntdataToWorkflow(this.data.workflowStepId, result.id).then((res)=>{
          if (res){
            this.notificationService.showSnackbarMessage(
              'Messages.assignEntdataToWorkflowSuccess'
            );
            this.matDialogRef.close('success');
          }
        })
      }
    });
  }
}
