import { Component, EventEmitter, Input, Output } from '@angular/core'
import { EntityItemFilter } from '../filters'

@Component({
  selector: 'app-entity-selector-autocomplete',
  templateUrl: './entity-selector-autocomplete.component.html',
  styleUrls: ['./entity-selector-autocomplete.component.css'],
})
export class EntitySelectorAutocompleteComponent {
  public showSpinner: boolean = false
  public SHOWTABLE = false
  @Input() LABEL: string
  @Input() DISABLED: boolean
  @Input() ENTITY: string
  @Output() selectEntityItem = new EventEmitter<any>()

  entityFilters: EntityItemFilter = new EntityItemFilter()

  public selectItem(ev) {
    this.selectEntityItem.emit(ev)
  }
}
