import { SettingsService } from './../../../../../services/settings.service';
import {
  AfterViewInit,
  Component,
  DestroyRef,
  DoCheck,
  Input,
  OnInit,
  ViewChild,
} from '@angular/core';
import { QuoteService } from 'src/app/services/quote.service';
import { MatTable } from '@angular/material/table';
import { MatDialog } from '@angular/material/dialog';
import { CdkDragDrop, moveItemInArray } from '@angular/cdk/drag-drop';
import { ActivatedRoute } from '@angular/router';
import { MeasurementUnit } from 'src/app/common/enums';
import { ConfQuote, ConfQuoteLine, Field } from 'src/app/model';
import { LoginService } from 'src/app/services/login.service';
import { NotificationService } from 'src/app/services/notification.service';
import { Location } from '@angular/common';
import { TranslateService } from '@ngx-translate/core';
import { EntityService } from 'src/app/services/entity.service';
import { ERPWarehouse } from 'src/app/models/erpwarehouse';
import { ErpWarehouseService } from 'src/app/services/erp/erp-warehouse.service';
import { QrcodeScannerComponent } from '../../../../qrcode-scanner/qrcode-scanner.component';
import { EntityItemFilter } from '../../../../filters';
import { LayoutService } from '../../../../../services/layout.service';
import { takeUntilDestroyed } from '@angular/core/rxjs-interop';

export interface PeriodicElement {
  name: string;
  position: number;
  weight: number;
  symbol: string;
}

@Component({
  selector: 'app-productlist-field',
  templateUrl: './productlist-field.component.html',
  styleUrls: ['./productlist-field.component.css'],
})
export class ProductlistFieldComponent
  implements OnInit, AfterViewInit, DoCheck
{
  displayedColumns: string[] = [
    'id',
    'product_name',
    'qty',
    'unit',
    'discount',
    'discount2',
    'customPrice',
    'vat',
    'piecePrice',
    'smallBoxPrice',
    'boxPrice',
    'paletPrice',
    'nettoPrice',
    'stock',
    'availability',
    'totalPieces',
    'totalPrice',
    'warehouse',
    'containers',
    'extranumeric1',
    'extranumeric2',
    'extranumeric3',
    'extrastr1',
    'extrastr2',
    'extraDrpDown1',
    'extraDrpDown2',
    'extraDrpDown3',
    'extrastr3',
    'del',
    'order',
    'qr-scanner-search',
    'qr-scanner-validator',
  ];
  selectedColumns: string[] = [];
  localstorageKey: string;
  confQuote: ConfQuote;
  gridCols: any[] = [];
  product_fields: Field[] = [];
  productFieldsForScanner: Field[] = [];
  isHandset: boolean = false;
  disableCustomPrice: boolean;
  addressItem: any;
  addressId: string;
  options: string[] = ['s', 'c', 'l'];
  public warehouses: ERPWarehouse[] = [];
  containers: number | null = null;

  @ViewChild(MatTable) table: MatTable<any>;

  @Input() ITEM: any;
  @Input() ITEMID: any;
  @Input() disabled: boolean = false;
  @Input() allAttr: any;

  constructor(
    private route: ActivatedRoute,
    private __location: Location,
    private quoteService: QuoteService,
    private dialog: MatDialog,
    public settingsService: SettingsService,
    public loginService: LoginService,
    private translateService: TranslateService,
    private entityService: EntityService,
    private erpwarehouseService: ErpWarehouseService,
    private notificationService: NotificationService,
    public layoutService: LayoutService,
    private destroyRef: DestroyRef
  ) {}
  ngDoCheck(): void {
    let tmpaddressAttr = this.allAttr.find(
      (x) => x.fld_code == 'singleEntity' && x.for_entity == 'address'
    )?.value;

    if (this.addressId != tmpaddressAttr && tmpaddressAttr) {
      this.addressId = tmpaddressAttr;
      this.getAddressItem(this.addressId);
    }
  }

  ngAfterViewInit(): void {
    if (this.ITEMID != 'new') {
      this.getSavedLines(this.ITEMID);
    } else {
      this.addItemLine(null);
    }
  }

  ngOnInit(): void {
    this.getProductFields();

    this.confQuote = new ConfQuote();
    this.confQuote.totalDiscount = 0;
    this.confQuote.totalAmount = 0;
    this.confQuote.quotelines = [];

    let savedQuoteId = this.route.snapshot.paramMap.get('id');

    this.ITEM.prodlines = [];

    this.disableCustomPrice = this.settingsService.getSavedSettings(
      'quoteConfDisableCustomPrice'
    )[0]
      ? this.settingsService.getSavedSettings('quoteConfDisableCustomPrice')[0]
          ?.value == 'true'
      : false;

    this.layoutService.isHandset$
      .pipe(takeUntilDestroyed(this.destroyRef))
      .subscribe((x) => {
        this.isHandset = x;
      });

    let addressAttr = this.allAttr.find(
      (x) => x.fld_code == 'singleEntity' && x.for_entity == 'address'
    );
    this.addressId = addressAttr?.value;
    if (addressAttr && addressAttr.value) {
      this.getAddressItem(addressAttr.value);
    }

    this.getWarehouses();
  }

  public getWarehouses() {
    this.erpwarehouseService.getActiveWarehouses().then((res) => {
      this.warehouses = res;
    });
  }

  rememberSelectedCols() {
    this.makeGridCols();

    let additionalLocalStorageKey = this.isHandset ? 'mobile' : 'desktop';
    this.localstorageKey = 'grid_prodlist_' + additionalLocalStorageKey;
    let savedColumnsJSON = localStorage.getItem(this.localstorageKey);
    if (savedColumnsJSON) {
      let savedColumns = JSON.parse(savedColumnsJSON).filter(
        (x) =>
          x != 'deactivateBulkAction' &&
          x != 'activateBulkAction' &&
          x != 'editBulkAction'
      );
      this.selectedColumns = [];

      for (let savedColumn of savedColumns) {
        if (
          this.displayedColumns.find((x) => x == savedColumn) ||
          this.product_fields.find((x) => x.fld_code == savedColumn)
        ) {
          this.selectedColumns.push(savedColumn);
        }
      }
    } else {
      this.selectedColumns = [
        'id',
        'product_name',
        'qty',
        'unit',
        'discount',
        'discount2',
        'customPrice',
        'vat',
        'availability',
        'totalPieces',
        'totalPrice',
        'del',
      ];
    }
    this.table.renderRows();
  }
  private makeGridCols() {
    this.gridCols.push(
      {
        key: 'id',
        desc: '#',
      },
      {
        key: 'product_name',
        desc: this.translateService.instant('QuoteConfigurator.prodName'),
      },
      {
        key: 'qty',
        desc: this.translateService.instant('QuoteConfigurator.qty'),
      },
      {
        key: 'unit',
        desc: this.translateService.instant('QuoteConfigurator.unit'),
      },
      {
        key: 'discount',
        desc: this.translateService.instant('QuoteConfigurator.discount'),
      },
      {
        key: 'discount2',
        desc:
          this.translateService.instant('QuoteConfigurator.discount') + ' ' + 2,
      },
      {
        key: 'customPrice',
        desc: this.translateService.instant('ProdConfigurator.price'),
      },
      {
        key: 'vat',
        desc: this.translateService.instant('QuoteConfigurator.vat'),
      },
      {
        key: 'backorder',
        desc: this.translateService.instant('ProdConfigurator.backorder'),
      },
      {
        key: 'piecePrice',
        desc: this.translateService.instant('QuoteConfigurator.piecePrice'),
      },
      {
        key: 'smallBoxPrice',
        desc: this.translateService.instant('QuoteConfigurator.smallBoxPrice'),
      },
      {
        key: 'boxPrice',
        desc: this.translateService.instant('QuoteConfigurator.boxPrice'),
      },
      {
        key: 'paletPrice',
        desc: this.translateService.instant('QuoteConfigurator.paletPrice'),
      },
      {
        key: 'nettoPrice',
        desc: this.translateService.instant('ProdConfigurator.NettoPrice'),
      },
      {
        key: 'stock',
        desc: this.translateService.instant('QuoteConfigurator.stock'),
      },
      {
        key: 'availability',
        desc: this.translateService.instant('QuoteConfigurator.availability'),
      },
      {
        key: 'totalPieces',
        desc: this.translateService.instant('QuoteConfigurator.totalPieces'),
      },
      {
        key: 'totalPrice',
        desc: this.translateService.instant('QuoteConfigurator.totalPrice'),
      },
      {
        key: 'warehouse',
        desc: this.translateService.instant('ERPWarehouse.warehouse'),
      },
      {
        key: 'containers',
        desc: this.translateService.instant('QuoteConfigurator.containers'),
      },
      {
        key: 'extranumeric1',
        desc: this.settingsService.getSavedSettings('quoteConfNumFld1')[0]
          ? this.settingsService.getSavedSettings('quoteConfNumFld1')[0]?.value
          : this.translateService.instant('ProdConfigurator.extrFld') + ' 1',
      },
      {
        key: 'extranumeric2',
        desc: this.settingsService.getSavedSettings('quoteConfNumFld2')[0]
          ? this.settingsService.getSavedSettings('quoteConfNumFld2')[0]?.value
          : this.translateService.instant('ProdConfigurator.extrFld') + ' 2',
      },
      {
        key: 'extranumeric3',
        desc: this.settingsService.getSavedSettings('quoteConfNumFld3')[0]
          ? this.settingsService.getSavedSettings('quoteConfNumFld3')[0]?.value
          : this.translateService.instant('ProdConfigurator.extrFld') + ' 3',
      },
      {
        key: 'extrastr1',
        desc: this.settingsService.getSavedSettings('quoteConfStrFld1')[0]
          ? this.settingsService.getSavedSettings('quoteConfStrFld1')[0]?.value
          : this.translateService.instant('ProdConfigurator.extrFld') + ' 4',
      },
      {
        key: 'extrastr2',
        desc: this.settingsService.getSavedSettings('quoteConfStrFld2')[0]
          ? this.settingsService.getSavedSettings('quoteConfStrFld2')[0]?.value
          : this.translateService.instant('ProdConfigurator.extrFld') + ' 5',
      },
      {
        key: 'extrastr3',
        desc: this.settingsService.getSavedSettings('quoteConfStrFld3')[0]
          ? this.settingsService.getSavedSettings('quoteConfStrFld3')[0]?.value
          : this.translateService.instant('ProdConfigurator.extrFld') + ' 6',
      },
      {
        key: 'extraDrpDown1',
        desc: this.settingsService.getSavedSettings('quoteConfDrpDownFld1')[0]
          ? this.settingsService.getSavedSettings('quoteConfDrpDownFld1')[0]
              ?.value
          : this.translateService.instant('ProdConfigurator.extrFld') + ' 7',
      },
      {
        key: 'extraDrpDown2',
        desc: this.settingsService.getSavedSettings('quoteConfDrpDownFld2')[0]
          ? this.settingsService.getSavedSettings('quoteConfDrpDownFld2')[0]
              ?.value
          : this.translateService.instant('ProdConfigurator.extrFld') + ' 8',
      },
      {
        key: 'extraDrpDown3',
        desc: this.settingsService.getSavedSettings('quoteConfDrpDownFld3')[0]
          ? this.settingsService.getSavedSettings('quoteConfDrpDownFld3')[0]
              ?.value
          : this.translateService.instant('ProdConfigurator.extrFld') + ' 9',
      },
      {
        key: 'qr-scanner-search',
        desc: this.translateService.instant(
          'QuoteConfigurator.qrScannerSearch'
        ),
      },
      {
        key: 'qr-scanner-validator',
        desc: this.translateService.instant(
          'QuoteConfigurator.qrScannerValidator'
        ),
      },
      {
        key: 'del',
        desc: this.translateService.instant('QuoteConfigurator.del'),
      },
      {
        key: 'order',
        desc: this.translateService.instant('QuoteConfigurator.orderLine'),
      },
      {
        key: 'containers',
        desc: this.translateService.instant('QuoteConfigurator.containers'),
      }
    );
  }

  private getSavedLines(quoteId: string) {
    this.quoteService.getSavedConf(quoteId).then((res) => {
      this.ITEM.prodlines = res;
      this.confQuote.quotelines = res;
      this.addItemLine(null);
      this.refresh();
    });
  }

  public addItemLine(item: any) {
    let newLine: ConfQuoteLine = new ConfQuoteLine();
    newLine.product = item;
    newLine.availability = 1;
    newLine.qty = 1;
    newLine.numFld1 = 0;
    newLine.discount = this.confQuote.totalDiscount;
    newLine.discount2 = 0;
    newLine.product_id = item?.id ?? null;
    newLine.unit = MeasurementUnit.Piece;
    newLine.aa = this.confQuote.quotelines.length + 1;
    newLine.price = +item?.product_price_per_piece ?? 0;
    newLine.vat = 24;
    newLine.warehouse_id = this.settingsService.getSavedSettings(
      'quoteConfDefaultWarehouse'
    )[0]
      ? +this.settingsService.getSavedSettings('quoteConfDefaultWarehouse')[0]
          .value
      : null;

    this.confQuote.quotelines.push(newLine);
    this.refresh();
  }

  public removeItemLine(index: number) {
    this.confQuote.quotelines.splice(index, 1);
    this.reorderProducts();
    this.refresh();
  }

  public refresh() {
    this.checkForContainerRounding();
    this.calculator();
    this.table.renderRows();
    this.ITEM.prodlines = this.confQuote.quotelines.filter((x) => x.product);

    let quote_total_value = this.allAttr.find(
      (x) => x.fld_code_ent == 'quote_total_value'
    );
    if (quote_total_value) {
      quote_total_value.value = this.confQuote.totalAmount;
    }

    let order_total_value = this.allAttr.find(
      (x) => x.fld_code_ent == 'order_total_value'
    );
    if (order_total_value) {
      order_total_value.value = this.confQuote.totalAmount;
    }
  }

  public ChangeUnit(line: ConfQuoteLine) {
    if (+line.unit == MeasurementUnit.Piece) {
      line.price = +line.product.product_price_per_piece;
    } else if (+line.unit == MeasurementUnit.SmallBox) {
      line.price = +line.product.product_price_per_small_box;
    } else if (+line.unit == MeasurementUnit.Box) {
      line.price = +line.product.product_price_per_box;
    } else if (+line.unit == MeasurementUnit.Palet) {
      line.price = +line.product.product_price_per_palet;
    }
    this.calculator();
  }

  private calculator() {
    this.confQuote.totalAmount = 0;
    for (let line of this.confQuote.quotelines) {
      if (!line.product) {
        continue;
      }

      this.confQuote.totalAmount += this.getTotalPrice(line);
    }
    this.confQuote.totalAmount = parseFloat(
      this.confQuote.totalAmount.toFixed(2)
    );
  }

  public goBack() {
    this.__location.back();
  }

  public getTotalPieces(line: ConfQuoteLine): number {
    let total: number = 0;
    let prod_to_smBox: number = line.product.product_products_to_small_box
      ? +line.product.product_products_to_small_box
      : 1;
    let smBox_to_Box: number = line.product.product_small_boxes_to_box
      ? +line.product.product_small_boxes_to_box
      : 1;
    let box_to_Palet: number = line.product.product_boxes_to_pallet
      ? +line.product.product_boxes_to_pallet
      : 1;

    if (+line.unit == MeasurementUnit.Piece) {
      total = line.qty;
    } else if (+line.unit == MeasurementUnit.SmallBox) {
      total = line.qty * prod_to_smBox;
    } else if (+line.unit == MeasurementUnit.Box) {
      total = line.qty * prod_to_smBox * smBox_to_Box;
    } else if (+line.unit == MeasurementUnit.Palet) {
      total = line.qty * prod_to_smBox * smBox_to_Box * box_to_Palet;
    }
    return total;
  }

  public getTotalPrice(line: ConfQuoteLine): number {
    let valueWithDiscount: number = (1 - line.discount / 100) * line.price;
    let valueWithDiscount2: number =
      (1 - line.discount2 / 100) * valueWithDiscount;
    return parseFloat((line.qty * valueWithDiscount2).toFixed(2));
  }

  drop(event: CdkDragDrop<string[]>) {
    moveItemInArray(
      this.selectedColumns,
      event.previousIndex,
      event.currentIndex
    );
    this.storeSettings();
  }

  public upItemLine(index: number) {
    moveItemInArray(this.confQuote.quotelines, index - 1, index);
    this.confQuote.quotelines = this.confQuote.quotelines.slice();
    this.reorderProducts();
  }

  public downItemLine(index: number) {
    moveItemInArray(this.confQuote.quotelines, index + 1, index);
    this.confQuote.quotelines = this.confQuote.quotelines.slice();
    this.reorderProducts();
  }

  public fillProdlineItem(item: any, line: ConfQuoteLine) {
    line.product = item;
    line.product_id = item.id;
    line.price = +item?.product_price_per_piece ?? 0;

    let kathestosPerioxis = this.addressItem?.data?.find(
      (x) => x.fld_code_ent == 'address_fpa'
    )?.value;

    if (kathestosPerioxis == 'Κανονικό') {
      switch (item.product_vat) {
        case 'Κανονικός 24%':
          line.vat = 24;
          break;
        case 'Μειωμένος 13%':
          line.vat = 13;
          break;
        case 'Υπερμειωμένος 6%':
          line.vat = 6;
          break;
        default:
          line.vat = 24;
          break;
      }
    } else if (kathestosPerioxis == 'Μειωμένο (-30%)') {
      switch (item.product_vat) {
        case 'Κανονικός 24%':
          line.vat = 17;
          break;
        case 'Μειωμένος 13%':
          line.vat = 9;
          break;
        case 'Υπερμειωμένος 6%':
          line.vat = 4;
          break;
        default:
          line.vat = 17;
          break;
      }
    } else {
      line.vat = 24;
    }

    this.addItemLine(null);
  }

  private getAddressItem(addressId: string) {
    this.entityService.geEntityItem('address', addressId).then((resItem) => {
      this.addressItem = resItem;
    });
  }

  storeSettings() {
    let delIndex = this.selectedColumns.findIndex((x) => x == 'del');
    if (delIndex > -1) {
      this.selectedColumns.splice(delIndex, 1);
      this.selectedColumns.push('del');
    }
    localStorage.setItem(
      this.localstorageKey,
      JSON.stringify(this.selectedColumns)
    );
  }

  changeTotalDisc() {
    let emptyLine = this.confQuote.quotelines.find((x) => x.product == null);
    emptyLine.discount = this.confQuote.totalDiscount;
  }

  private getProductFields() {
    this.entityService.getFldsEntityByName('product').then((res) => {
      this.productFieldsForScanner = res.fields;
      this.product_fields = res.fields.filter(
        (x) =>
          x.isGridColumn == 1 &&
          x.fld_code != 'product_name' &&
          x.fld_code != 'piecePrice' &&
          x.fld_code != 'smallBoxPrice' &&
          x.fld_code != 'boxPrice' &&
          x.fld_code != 'paletPrice' &&
          x.fld_code != 'nettoPrice' &&
          x.fld_code != 'stock'
      );
      this.rememberSelectedCols();
    });
  }

  public getFldTranslation(fld: Field): string {
    return this.loginService.getLoginUser().lang == 'en'
      ? fld.label_en
      : fld.label_gr;
  }

  public getProdFldHeader(fld: Field): string {
    let tmp = this.product_fields.find((x) => x.fld_code == fld.fld_code);
    return this.getFldTranslation(tmp);
  }

  updateSelectedColumns($event) {
    let difference = this.selectedColumns
      .filter((item) => !$event.includes(item))
      .concat($event.filter((item) => !this.selectedColumns.includes(item)));
    if (this.selectedColumns.includes(difference[0])) {
      this.selectedColumns = this.selectedColumns.filter(
        (item) => item !== difference[0]
      );
    } else {
      this.selectedColumns.push(difference[0]);
    }
    this.storeSettings();
  }

  // Code that is used in Karag Installation
  private checkForContainerRounding() {
    this.confQuote.quotelines.forEach((line) => {
      // Calculate the container number. Must be an integer
      if (
        line.product !== null &&
        line.product.hasOwnProperty('product_alternate_mu') &&
        line.product.product_alternate_mu !== null &&
        line.product.hasOwnProperty('product_box_available') &&
        line.product.product_box_available == 1
      ) {
        line.containers = Math.ceil(
          parseFloat(
            (line.qty / Number(line.product.product_alternate_mu)).toFixed(2)
          )
        );
        setTimeout(() => {
          line.qty = parseFloat(
            (line.product.product_alternate_mu * line.containers).toFixed(2)
          );
        }, 5000);
      }
    });
  }

  calculateQuantity(element: any) {
    element.containers = Math.ceil(element.containers);
    element.qty = parseFloat(
      (element.product.product_alternate_mu * element.containers).toFixed(2)
    );
  }

  reorderProducts() {
    let counter = 1;
    for (let line of this.confQuote.quotelines) {
      line.aa = counter;
      counter++;
    }
  }

  openQRScanner(line: ConfQuoteLine, mode: string) {
    const dialogRef = this.dialog.open(QrcodeScannerComponent, {
      data: null,
      height: '80%',
      width: '80%',
    });

    dialogRef.afterClosed().subscribe((productCode) => {
      if (productCode !== undefined) {
        console.log(productCode);
        if (mode === 'search') {
          this.getProductFromScanner(productCode, line);
        } else {
          this.validateProductWithScanner(productCode, line);
        }
      }
    });
  }

  private getProductFromScanner(productCode: number, line: ConfQuoteLine) {
    const filter = new EntityItemFilter();
    const searchField = this.settingsService.getSavedSettings(
      'productFieldForQrCode'
    )[0];
    const productCodeField = this.productFieldsForScanner.filter(
      (field) => field.id === Number(searchField.value)
    )[0];
    productCodeField.search_from = String(productCode);
    filter.searchFields = [productCodeField];

    this.entityService.getEntityDataList(filter, 'product').then((res) => {
      if (res.data.length > 0) {
        this.fillProdlineItem(res.data[0], line);
        this.notificationService.showSnackbarMessage(
          'Messages.selectedProductForProdList'
        );
      } else {
        this.notificationService.showSnackbarMessage(
          'Messages.noProductForProdList'
        );
      }
    });
  }

  private validateProductWithScanner(productCode: any, line: ConfQuoteLine) {
    const searchFieldId = Number(
      this.settingsService.getSavedSettings('productFieldForQrCode')[0].value
    );
    this.quoteService
      .validateProduct(line.product_id, searchFieldId, productCode)
      .then((res) => {
        if (res.validated) {
          this.notificationService.showSnackbarMessage(
            'Messages.validatedProductSuccess'
          );
        } else {
          this.notificationService.showSnackbarMessage(
            'Messages.validatedProductFail'
          );
        }
      });
  }
}
