import {Entdata, PagedData, ProjectDto, ReturnData, Sprint, Taskgroup} from '../model';
import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { BaseService } from './base.service';
import { TaskFilter } from '../common/filters';
import {Subject} from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class TaskService extends BaseService {

  projectSprints = new Subject<Sprint[]>();
  refreshProjectData = new Subject<boolean>();
  applyTasksFilter = false;

  constructor(private httpClient: HttpClient) {
    super(httpClient);
  }

  public getUserGroups(): Promise<Taskgroup[]> {
    return this.get<Taskgroup[]>(this.apiUrl + 'tasks/groups');
  }

  public getUserTasks(taskFilters: TaskFilter): Promise<PagedData<Entdata>> {
    return this.post<PagedData<Entdata>>(this.apiUrl + 'tasks?page=' + taskFilters.currentPage, taskFilters);
  }

  public addTaskgroup(taskgroup: Taskgroup): Promise<ReturnData<Taskgroup>> {
    return this.post<ReturnData<Taskgroup>>(this.apiUrl + 'taskgroups', taskgroup);
  }

  public delTaskgroup(taskgroup_id: number): Promise<ReturnData<Taskgroup[]>> {
    return this.delete<ReturnData<Taskgroup[]>>(this.apiUrl + 'taskgroups/' + taskgroup_id);
  }

  public getTaskByProjectId(taskFilters: TaskFilter): Promise<ProjectDto> {
    return this.post<ProjectDto>(this.apiUrl + 'projects/tasks/' + taskFilters.project_id, taskFilters);
  }

}
