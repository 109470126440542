import { Component, Input, OnInit, ViewChild } from '@angular/core';
import { MatTable } from '@angular/material/table';
import { SemiProduct } from 'src/app/model';
import { ProductService } from 'src/app/services/product.service';

@Component({
  selector: 'app-semi-productlist-field',
  templateUrl: './semi-productlist-field.component.html',
  styleUrls: ['./semi-productlist-field.component.css']
})
export class SemiProductlistFieldComponent implements OnInit {
  displayedColumns: string[] = ['id', 'category_name','semi_prod_name','qty'];
  dataSource: SemiProduct[] = [];
  constructor(
    private productService: ProductService
  ) { }
  @Input() ITEM: any;
  @Input() ITEMID: any;
  @Input() disabled: boolean = false;
  @ViewChild(MatTable) table: MatTable<any>;

  ngOnInit(): void {
    this.getSemiProdList();
  }

  private getSemiProdList() {
    this.productService.getSemiProductList(this.ITEMID).then(res=>{
      this.dataSource = res;
    });
  }
}
